<script>
import { ref, computed } from 'vue'
import { directive as clickaway } from 'vue-clickaway'
import { useRouter } from 'vue-router/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'drawer-action',
  components: {
    BaseIcon,
  },
  directives: {
    clickaway,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()

    const isConfirmVisible = ref(false)
    const isProcessing = ref(false)

    const typeClass = computed(() => {
      return {
        primary: 'bg-primary text-white',
        danger: 'bg-fg-error text-white',
      }[props.action.type || 'primary']
    })

    const isDisabled = computed(() => {
      return props.disabled || props.action.disabled
    })

    const confirmActions = computed(() => {
      const actions = props.action.confirm?.actions

      return (
        actions || [{ icon: 'plain/no' }, { icon: 'plain/yes', isSubmit: true }]
      )
    })

    const confirmText = computed(() => {
      if (isProcessing.value) {
        return props.action.confirm?.progressText || 'Processing...'
      }

      return props.action.confirm?.text || 'Are you sure?'
    })

    function onClick() {
      if (props.action.confirm) {
        isConfirmVisible.value = true
      } else if (props.action.click instanceof Function) {
        props.action.click()
      } else if (props.action.route) {
        router.push(props.action.route)
      } else {
        console.warn('Action has no handler')
      }
    }

    async function onConfirmAction(action) {
      if (action.isSubmit) {
        if (props.action.confirm?.submit instanceof Function) {
          isProcessing.value = true
          await props.action.confirm.submit()
        }
      }

      clear()
    }

    function clear() {
      isProcessing.value = false
      isConfirmVisible.value = false
    }

    function onClickaway() {
      if (!this.isProcessing) {
        clear()
      }
    }

    return {
      typeClass,
      isConfirmVisible,
      isDisabled,
      isProcessing,
      confirmActions,
      confirmText,
      onClick,
      onConfirmAction,
      onClickaway,
    }
  },
}
</script>

<template>
  <div
    v-clickaway="onClickaway"
    class="relative w-full overflow-hidden rounded-md"
  >
    <button
      class="w-full cursor-pointer p-2.5 text-left hover:bg-dark disabled:cursor-not-allowed disabled:bg-transparent disabled:opacity-50"
      :disabled="isDisabled"
      @click="onClick"
    >
      <span class="flex items-start justify-between">
        <span
          class="mr-5 flex h-11 w-11 shrink-0 items-center justify-center rounded-full sm:h-12 sm:w-12"
          :class="typeClass"
        >
          <base-icon :svg="action.icon" size="md" />
        </span>
        <span class="block min-w-0 grow">
          <span class="block truncate font-bold leading-7">
            {{ action.label }}
          </span>
          <span
            class="block overflow-hidden break-words text-sm leading-tight text-eonx-neutral-600"
          >
            {{ action.description }}
          </span>
        </span>
      </span>
    </button>
    <transition
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="isConfirmVisible"
        class="absolute inset-0 flex h-full w-full transform items-center justify-between rounded-md transition duration-100 ease-out"
        :class="typeClass"
      >
        <span class="px-5 font-bold">
          {{ confirmText }}
        </span>
        <div
          v-if="!isProcessing"
          class="flex shrink-0 items-center space-x-2.5 pr-[15px]"
        >
          <button
            v-for="confirmAction in confirmActions"
            :key="confirmAction.label"
            class="flex h-10 w-10 items-center justify-center rounded-full bg-white"
            :class="[
              confirmAction.isSubmit ? 'text-fg-success' : 'text-fg-error',
            ]"
            @click="onConfirmAction(confirmAction)"
          >
            <base-icon :svg="confirmAction.icon" size="md" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
